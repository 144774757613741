import { VoucherV2 } from "../vouchersV2Slice.tsx";
import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle.ts";
import _ from "lodash";

export function minBasketPriceValidation(
  voucher: VoucherV2,
  orderArticles: OrderArticle[],
  priceLineId: number | null
): boolean {
  if (voucher.voucherdef.minBasketPrice != null && voucher.voucherdef.minBasketPrice > 0) {
    return (
      _.sumBy(orderArticles, (orderArticle) => getTotalPrice(orderArticle, priceLineId)) >=
      voucher.voucherdef.minBasketPrice
    );
  } else {
    return true;
  }
}
