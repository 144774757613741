import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Order from "../models/order/Order";
import KeyboardState from "../../../kiosk/utils/KeyboardState";
import { setSelectedArticlegroup } from "./menuSlice";
import { Language } from "../../../types/shared/language";
import { Salesarea } from "../../../types/shared/Salesarea";
import { PaymentMethods } from "../../../types/shared/payProviders.ts";

const appStyling = sessionStorage.getItem("V5.global.appStyling") ?? "{}";

export interface PaymentSetting {
  active: boolean;
  id: "ideal" | "creditcard" | string;
  name: "iDEAL" | "Card" | "Online" | "Cash" | string;
  image: string;
  /**
   * An issuer represents a bank that handles iDeal payments, for instance ING or ABN AMRO
   */
  issuers:
    | {
        id: string;
        name: string;
        image: string;
        issuers: any;
      }[]
    | null;
}

export type CheckTableIsOpenConfig = {
  enabled: boolean;
};

export type OneTimeUpsellWhere = "onOpenMenuPage" | "onOpenShoppingCart";

export type TransactionCostsConfig = {
  enabled: true;
  product_id: number;
  calculation_method: "fixed" | "percentage";
  calculation_value: number;
  max_value: number;
};

export interface SessionState {
  distance: -1 | number | null;
  locationDistanceCheck: 0 | number | null;
  sessionId: string;
  lang: Language | null;
  paymentMethods: PaymentMethods;
  showLangselLanguages: string;
  [key: string]: any;
}

export type JSGlobalStyles = Record<string, Record<string, any>>;

interface GlobalState {
  sessionState: SessionState;
  appStyling: any;
  liveInterfacePreviewStyling: JSGlobalStyles;
  salesarea: Salesarea;
  userSelectedLanguage: Language | null;
  order: Order | null;
  webcode: string;
  hiddenIframeURL: string;
  articleSearchText: string;
  isArticleSearchTextEmpty: boolean;
  printerErrorState: number;
  hasInternetConnection: boolean;
  keyboard: KeyboardState;
  eancodeUnknownDialogIsOpen: boolean;
  scanner_is_enabled: boolean;
  hideItemsBySelectedAllergens: boolean;
  serverTimestamp: number | undefined;
  sessionOutOfSync: number | null;
  refreshApp: number;
  tableIsOpenInPos: boolean | undefined;
  clientTimeDifferenceWithServer: number;
  openStateAndDuration: { isOpen: boolean; duration: Duration };
}

const orderFromSessionStorage = sessionStorage.getItem("V5.order");

const initState: GlobalState = {
  sessionState: {
    locationDistanceCheck: null,
    sessionId: "",
    distance: null,
    lang: null,
    paymentMethods: [],
    showLangselLanguages: "[]",
  },
  appStyling,
  liveInterfacePreviewStyling: {},
  salesarea: {
    id: 0,
    venue_id: 0,
    use_packagings: false,
    start_url: null,
    applicationLanguage: "en",
    nlFlag: "",
    genQRCode: 0,
    defaultSMSLang: "",
    show_welcome_screen: false,
    type_of_shopping_cart_button: "SMALL",
    systemOnline: 0,
    systemOfflineReason: null,
    autoAddProductId: -1,
    useProductStock: false,
    menukaartVestigingId: null,
    kiosk_compact_options: false,
    confirm_age_by_employee: false,
    checkTableIsOpen: { enabled: false },
    piggy: {
      loyalty: { enabled: false },
      giftcards: { enabled: false },
      vouchers: { enabled: false },
      prepaid: { enabled: false },
    },
    mwise: {
      enabled: false,
    },
    customFields: null,
    media_urls: {},
    kioskv5_media: {
      scheduled_media: {
        enabled: false,
        schedule: [],
      },
    },
    terms_and_conditions: {
      should_show: false,
      algemene_voorwaarden_url: null,
      privacy_statement_url: null,
    },
    transaction_costs: {
      enabled: false,
    },
    proppos: {
      secret_string: "",
      device_id: "",
      enabled: false,
      ip_address: "",
      polling_rate_in_milliseconds: 1000,
    },
    delivery_settings: {
      enabled: false,
    },
    check_calculate_settings: {
      enabled: false,
    },
    customized_texts: {},
    htmlTranslations: {},
    payment_settings: null,
    contantBetalen: false,
    payDirect: false,
    use_arrangements: false,
    arrangements_demo: false,
    wait_for_dispatch: false,
    schiphol_boarding_pass_scanner: {
      enabled: false,
    },
    askForReceipt: false,
    betweenOrderPage: {
      enabled: false,
    },
    custom_data: {},
    paymentMethods: [],
    allowVouchers: 0,
    upsellSettings: {
      is_active: false,

      oneTimeUpsell: {
        isActive: false,
        shownAt: "onOpenMenuPage",
        upsell_on_order_mode: "both",
        title: "",
        text: "",
        productIds: "",
        translations: {},
      },
      upsellNoFood: {
        is_active: false,
        title: "",
        productIds: "[]",
        text: "",
        translations: {},
      },
      upsellNoDrinks: {
        is_active: false,
        title: "",
        productIds: "[]",
        text: "",
        translations: {},
      },
    },
    printer_check_state_enabled: false,
    salesarea_type: null,
    tipPercentages: {
      lower_tip_percentage: 5,
      medium_tip_percentage: 10,
      high_tip_percentage: 15,
    },
    askForTip: false,
    tipProduct: 0,
  },
  userSelectedLanguage: (sessionStorage.getItem("V5.userSelectedLanguage") as Language) ?? null,
  order: orderFromSessionStorage ? JSON.parse(orderFromSessionStorage) : null,
  webcode: localStorage.getItem("V5.webcode") ?? "",
  hiddenIframeURL: "",
  articleSearchText: "",
  isArticleSearchTextEmpty: true,
  printerErrorState: 0,
  hasInternetConnection: true,
  keyboard: {
    timestamp: 0,
    isOpen: false,
    layout: "default",
    value: "",
    position: "push",
  },
  eancodeUnknownDialogIsOpen: false,
  scanner_is_enabled: true,
  hideItemsBySelectedAllergens: false,
  sessionOutOfSync: null,
  serverTimestamp: undefined,
  clientTimeDifferenceWithServer: 0,
  openStateAndDuration: { duration: {}, isOpen: true },
  refreshApp: 0,
  tableIsOpenInPos: undefined,
};
export const globalSlice = createSlice({
  name: "global",
  initialState: initState,
  reducers: {
    setSalesarea: (state, action: PayloadAction<Salesarea>) => {
      state.salesarea = action.payload;
    },
    setSessionState: (state, action: PayloadAction<any>) => {
      state.sessionState = action.payload;
    },
    setAppStyling: (state, action: PayloadAction<any>) => {
      // sessionStorage.setItem("V5.appStyling", JSON.stringify(action.payload));
      state.appStyling = action.payload;
    },
    liveInterfaceStylingPreviewChanged: (state, action: PayloadAction<any>) => {
      state.liveInterfacePreviewStyling = action.payload;
    },
    userSelectedLanguage: (state, action: PayloadAction<Language>) => {
      state.userSelectedLanguage = action.payload;
      sessionStorage.setItem("V5.userSelectedLanguage", action.payload);
    },
    orderCreated: (state, action) => {
      state.order = action.payload;
      sessionStorage.setItem("V5.order", JSON.stringify(action.payload));
    },
    setWebcode: (state, action: PayloadAction<string>) => {
      state.webcode = action.payload;
      localStorage.setItem("V5.webcode", action.payload);
    },
    setHiddenIframeURL: (state, action) => {
      state.hiddenIframeURL = action.payload;
    },
    setArticleSearchText: (state, action) => {
      state.articleSearchText = action.payload;
      state.isArticleSearchTextEmpty = state.articleSearchText === "";
    },
    setPrinterErrorState: (state, action: PayloadAction<number>) => {
      state.printerErrorState = action.payload;
    },
    setHasInternetConnection: (state, action: PayloadAction<boolean>) => {
      state.hasInternetConnection = action.payload;
    },

    keyboardClosed: (state) => {
      state.keyboard = { ...state.keyboard, isOpen: false, value: "" };
    },
    setKeyboardValue: (state, action) => {
      state.keyboard = { ...state.keyboard, value: action.payload };
    },
    onFocusKeyboard: (
      state,
      action: PayloadAction<{
        value: string;
        layout: KeyboardState["layout"];
        position?: KeyboardState["position"];
      }>
    ) => {
      state.keyboard = {
        timestamp: new Date().getTime(),
        value: action.payload.value,
        layout: action.payload.layout,
        isOpen: true,
        position: action.payload.position ?? "push",
      };
    },
    setEancodeUnknownDialogIsOpen: (state, action: PayloadAction<boolean>) => {
      state.eancodeUnknownDialogIsOpen = action.payload;
    },
    scannerEnabled: (state) => {
      state.scanner_is_enabled = true;
    },
    scannerDisabled: (state) => {
      state.scanner_is_enabled = false;
    },
    enabledHideItemsBySelectedAllergens: (state, action: PayloadAction<boolean>) => {
      state.hideItemsBySelectedAllergens = action.payload;
    },
    setSessionOutOfSync: (state) => {
      state.sessionOutOfSync = new Date().getTime();
    },
    setServerTimestamp: (state, action: PayloadAction<number>) => {
      state.serverTimestamp = action.payload;
      state.clientTimeDifferenceWithServer = action.payload * 1000 - new Date().getTime();
    },
    refreshApp: (state) => {
      state.refreshApp = new Date().getTime();
    },
    setTableIsOpenInPos: (state, { payload: value }: PayloadAction<boolean>) => {
      state.tableIsOpenInPos = value;
    },
    openStateAndDurationSet: (state, { payload: action }: PayloadAction<{ isOpen: boolean; duration: Duration }>) => {
      state.openStateAndDuration = action;
    },
    appInitialized: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedArticlegroup, (state) => {
      state.articleSearchText = "";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSalesarea,
  setSessionState,
  setAppStyling,
  userSelectedLanguage,
  orderCreated,
  setWebcode,
  setHiddenIframeURL,
  setArticleSearchText,
  liveInterfaceStylingPreviewChanged,
  setPrinterErrorState,
  setHasInternetConnection,
  setKeyboardValue,
  keyboardClosed,
  onFocusKeyboard,
  setEancodeUnknownDialogIsOpen,
  scannerEnabled,
  scannerDisabled,
  enabledHideItemsBySelectedAllergens,
  setServerTimestamp,
  setSessionOutOfSync,
  refreshApp,
  setTableIsOpenInPos,
  appInitialized,
  openStateAndDurationSet,
} = globalSlice.actions;

export default globalSlice.reducer;
