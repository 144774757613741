import OrderArticle from "../../models/order/OrderArticle.ts";

export default function buildDiscountsRecursively(
  orderArticles: OrderArticle[],
  result: {
    [orderArticleUuid: string]: {
      discount: number;
    }[];
  } = {},
  multiplier: number = 1
) {
  orderArticles
    .filter((orderArticle) => orderArticle.count > 0)
    .forEach((orderArticle) => {
      for (let i = 0; i < orderArticle.count * multiplier; i++) {
        if (result[orderArticle.uuid] == null) {
          result[orderArticle.uuid] = [];
        }
        result[orderArticle.uuid].push({ discount: 0 });
      }

      orderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
        buildDiscountsRecursively(orderOptionGroup.orderArticles, result, orderArticle.count);
      });
    });
  return result;
}
