import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store.tsx";
import _ from "lodash";
import OrderArticle from "../../models/order/OrderArticle.ts";
import { VoucherDefV2, VoucherV2 } from "../vouchersV2Slice.tsx";
import { selectVouchersV2 } from "./selectVouchersV2.tsx";
import { selectSalesAreaPriceLineId } from "../../useSalesAreaPriceLineId.ts";
import { selectArticleArticlegroupsMapUnfiltered } from "../../redux/selectors/selectArticleArticlegroupsMapUnfiltered.ts";
import { selectArticlegroupsMap } from "../../redux/selectors/selectArticlegroupsMap.ts";
import { minBasketPriceValidation } from "./minBasketPriceValidation.ts";
import { calculateDiscountForVoucher, DiscountsPerOrderArticleUuid } from "./calculateDiscountForVoucher.ts";
import buildDiscountsRecursively from "./buildDiscountsRecursively.ts";

const sorting: { [key in VoucherDefV2["discountType"]]: number } = {
  COLLECTION: 0,
  ADD_DISCOUNTED_PRODUCT: 1,
  PERCENTAGE_OFF_PRODUCT: 2,
  AMOUNT_OFF_PRODUCT: 3,
  PERCENTAGE_OFF_TOTAL: 4,
  AMOUNT_OFF_TOTAL: 5,
};

export const selectVoucherDiscounts = createSelector(
  [
    selectVouchersV2,
    (state: RootState) => state.shoppingCart.items,
    selectSalesAreaPriceLineId,
    selectArticleArticlegroupsMapUnfiltered,
    selectArticlegroupsMap,
  ],
  (vouchers: VoucherV2[], orderArticles: OrderArticle[], priceLineId, articleArticlegroupsMap, articlegroupsMap) => {
    const discounts: DiscountsPerOrderArticleUuid = buildDiscountsRecursively(orderArticles);

    [...vouchers]
      .sort((a, b) => sorting[a.voucherdef.discountType] - sorting[b.voucherdef.discountType])
      .filter((voucher) => minBasketPriceValidation(voucher, orderArticles, priceLineId))
      .forEach((voucher: VoucherV2) => {
        calculateDiscountForVoucher(
          voucher,
          orderArticles,
          priceLineId,
          discounts,
          articleArticlegroupsMap,
          articlegroupsMap
        );
      });

    return {
      discountsPerOrderArticle: _.mapValues(discounts, (discount) => ({
        discount: _.sumBy(discount, "discount"),
      })),
    };
  }
);
